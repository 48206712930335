import axios from 'axios';
import React from 'react';
import httpReducer from './httpReducer';

const apiUrl = process.env.REACT_APP_API_URL;

const initialHttpState = {
    isLoading: false,
    error: ''
};

export const HttpContext = React.createContext(null);

export function HttpProvider(props){
    const [ http, dispatch ] = React.useReducer(httpReducer, initialHttpState);
    const ctx = { http, dispatch };
    return (
        <HttpContext.Provider value={ctx}>
            { props.children }
        </HttpContext.Provider>
    );
}

export const usePost = () => {
    const { dispatch } = useHttp();
    const post = async (endpoint, data) => {
        dispatch({type: 'onRequest'});
        try{
            const result = await axios.post(`${apiUrl}/${endpoint}`, data);
            dispatch({type: 'onResponseSuccess'});
            return result.data ?? result;
        } catch {
            dispatch({type: 'onResponseError'});
        }
    };
    return { post };
};

export const useHttp = () => {
  const context = React.useContext(HttpContext);
  return context;  
};