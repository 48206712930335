import React from 'react';
import authReducer, { initialAuthDefault } from './authReducer';

export const AuthContext = React.createContext(null);
// TODO: check that if I'm logged in, and close the tab, I stay logged in
let initialAuth;
try {
    initialAuth = JSON.parse(localStorage.getItem('heroAuth')) ?? initialAuthDefault;
} catch {
    console.error('error parsing auth object from LS');
    initialAuth = initialAuthDefault;
}

export function AuthProvider(props){
    const [auth, dispatch] = React.useReducer(authReducer, initialAuth);
    React.useEffect(() => localStorage.setItem('heroAuth', JSON.stringify(auth)), [auth]);
    const ctx = { auth, dispatch };
    return (
        <AuthContext.Provider value={ctx}>
            { props.children }
        </AuthContext.Provider>
    );
}

export function useAuth(){
    const context = React.useContext(AuthContext);
    return context;
}