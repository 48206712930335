import Logout from "../logout/Logout";

export default function Header() {
    return (
        <header className="py-2 bg-primary">
            <div className="container-fluid">
                <div className="d-flex justify-content-between align-items-center">
                    <h1 className="mb-0">hiro</h1>
                    <Logout />
                </div>
            </div>
        </header>
    )
}