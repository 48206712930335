import React from "react";
import RadioImage from "../components/radios/radio-img";

export const Dashboard = () => {

    const nominees = [
        {   
            id: 1,
            firstName: "Matthew",
            lastName: "Maude",
            image: "matthew-maude.jpg"
        },
        {   
            id: 2,
            firstName: "Brendan",
            lastName: "Smidt"
        },
        {   
            id: 3,
            firstName: "Mike",
            lastName: "Johnson"
        },
        {   
            id: 4,
            firstName: "Tomasz",
            lastName: "Bzymek"
        },
        {   
            id: 5,
            firstName: "Alice",
            lastName: "Knapton",
            image: "alice-knapton.jpg"
        },
        {   
            id: 6,
            firstName: "Dewi",
            lastName: "Jones"
        },
        {   
            id: 7,
            firstName: "Ryan",
            lastName: "Brown"
        },
        {   
            id: 8,
            firstName: "Ross",
            lastName: "Barker"
        },
        {   
            id: 9,
            firstName: "Dani",
            lastName: "Hickman",
            image: "dani-hickman.jpg"
        },
        {   
            id: 10,
            firstName: "Theo",
            lastName: "Millward"
        },
        {   
            id: 11,
            firstName: "Roger",
            lastName: "Millward"
        },
        {   
            id: 12,
            firstName: "Joci",
            lastName: "Oliver",
            image: "joci-oliver.jpg"
        },
        {   
            id: 13,
            firstName: "Sandra",
            lastName: "Knapton"
        },
        {   
            id: 14,
            firstName: "Rachel",
            lastName: "Brooks"
        }
    ]

    const awards = [
        {
            id: 1,
            name: "All in a days work"
        },
        {
            id: 2,
            name: "Be Mine"
        },
        {
            id: 3,
            name: "Design Award"
        },
        {
            id: 4,
            name: "Don't go in there"
        },
        {
            id: 5,
            name: "For Franscape"
        },
        {
            id: 6,
            name: "Good bants"
        },
        {
            id: 7,
            name: "Good lad"
        },
        {
            id: 8,
            name: "Good scrum"
        },
        {
            id: 9,
            name: "Grab a Plunger"
        },
        {
            id: 10,
            name: "Hussain Bolt"
        },
        {
            id: 11,
            name: "It's Muscle Time"
        },
        {
            id: 12,
            name: "Just doing my job"
        },
        {
            id: 13,
            name: "Just keep Swimming"
        },
        {
            id: 14,
            name: "Kid's got game"
        },
        {
            id: 15,
            name: "Living Legend"
        },
        {
            id: 16,
            name: "Nothing from me"
        },
        {
            id: 17,
            name: "That's a good brew"
        },
        {
            id: 18,
            name: "That's dry"
        },
        {
            id: 19,
            name: "That's what she said!"
        },
        {
            id: 20,
            name: "The Brendan Smidt Award"
        },
        {
            id: 21,
            name: "The Matthew Maude seal of approval"
        },
        {
            id: 22,
            name: "The original hero",
            image: "medal-original-hero.png"
        },
        {
            id: 23,
            name: "The Ryan Brown Award"
        },
        {
            id: 24,
            name: "The sizzling sausage"
        },
        {
            id: 25,
            name: "The Stand Up award"
        },
        {
            id: 26,
            name: "The Theo Milward Award"
        },
        {
            id: 27,
            name: "You're my favourite"
        }
    ]

    return (
        <div className="container-fluid py-3 py-4">
            <h2>Dashboard</h2>

            <form>
                <div className="mb-4 text-center">
                    <label htmlFor="nominee" className="form-label">Nominee</label>
                    <div id="nominee-help" className="form-text">Select a team mate to nominate</div>
                    <div className="horizontal-scroll">
                        <div className="radio-scroll d-flex">
                            {nominees.map(nom => (
                                // CREATE ID AS A HOOK TO REPLACE STRING WITH ID FRIENDLY STRING
                                <div key={`nominee-${nom.firstName.toLowerCase()}-${nom.lastName.toLowerCase().replaceAll(" ","-").replaceAll("'", "").replaceAll("!", "")}`} id={`nominee-${nom.firstName.toLowerCase()}-${nom.lastName.toLowerCase()}`}>
                                    <RadioImage 
                                        inputName={"nominee"}
                                        id={nom.id}
                                        label={`${nom.firstName} ${nom.lastName}`}
                                        img={nom.image && `/images/users/${nom.image}`}
                                        circle
                                        />
                                </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
                <div className="mb-4 text-center">
                    <label htmlFor="award" className="form-label">Award</label>
                    <div id="award-help" className="form-text">Select an award</div>
                    <div className="horizontal-scroll">
                        <div className="radio-scroll d-flex">
                            {awards.map(award => (
                                // CREATE ID AS A HOOK TO REPLACE STRING WITH ID FRIENDLY STRING
                                <div key={`award-${award.name.toLowerCase()}`} id={`award-${award.name.toLowerCase().replaceAll(" ","-").replaceAll("'", "").replaceAll("!", "")}`}>
                                    <RadioImage 
                                        inputName={"award"}
                                        id={award.id}
                                        label={`${award.name}`}
                                        img={award.image && `/images/awards/${award.image}`}
                                        fallbackImg="/images/awards/medal-fallback.png"
                                        circle
                                        shadow
                                        />
                                </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
                <div className="form-floating mb-3">
                    <textarea className="form-control"
                        placeholder="Reason"
                        id="reason"
                        name="reason"
                        aria-describedby="reason"
                        style={{height: 200}}
                        required
                    ></textarea>
                    <label htmlFor="reason">Reason</label>
                    <div id="reason-help" className="form-text">Why are you nominating this person for an award?</div>
                </div>
                <button type="submit" className="btn btn-primary">Nominate</button>
            </form>
                       
        </div>
    );
}

export default Dashboard;