import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PublicRoute from './route/PublicRoute';
import Login from "./login/Login";
import { useHttp } from "./http/httpContext";
import "./styles/global.scss";
import Header from './layout/Header';
import Footer from './layout/Footer';
import PrivateRoute from './route/PrivateRoute';
import Dashboard from './dashboard/Dashboard';
import Loading from './layout/Loading';

function App() {
	const { http } = useHttp();
	
	// Derived state
	const { isLoading } = http;
	
	return (
		<>
			<Header />
			{
				isLoading ? <Loading /> : 
				<main>
					<Routes>
						<Route 
							path='/' 
							element={
								<PrivateRoute>
									<Dashboard />
								</PrivateRoute>
							} 
						/>
						<Route 
							path='/login' 
							element={
								<PublicRoute>
									<Login />
								</PublicRoute>
							} 
						/>
					</Routes>
				</main>
			}
			<Footer /> 
		</>
	);
}

export default App;
