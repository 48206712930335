import styles from './radio-img.module.scss';

export default function RadioImage({ inputName, id, label, img, fallbackImg, circle, shadow }) {
    return (
        <>
            <input type="radio" className={styles.customRadioInput} name={inputName} id={`${inputName}-${id}`} autocomplete="off" />
            <label className={styles.customRadioLabel + ' ' + styles.customRadioImage} htmlFor={`${inputName}-${id}`} aria-label={`Select ${label}`}>
                <div className={styles.imgWrap + (shadow ? ` ${styles.shadow}` : '') + (circle ? ` ${styles.circle}` : '') + " ratio ratio-1x1"}>
                    {img ? <img src={img} alt={label} /> : (fallbackImg && <img src={fallbackImg} alt="" /> )}
                </div>
                <span className={styles.label}>{label}</span>
            </label>  
        </>      
    )
}