import React from "react";
import { useAuth } from "../auth/authContext";

export const Logout = () => {
    const { dispatch, auth } = useAuth();
    const { isAuthenticated } = auth;

    const onLogout = () => {
        dispatch({type: 'logout'});
    };

    if(!isAuthenticated){
        return <></>;
    }
    return (
        <div className="">
            <button
                onClick={onLogout}
                type="button"
                className="btn btn-secondary"
                >
                    Logout
            </button>
        </div>
    );
}

export default Logout;