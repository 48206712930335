import axios from "axios";

export const initialAuthDefault = {
    isAuthenticated: false,
    jwt: '',
    user: null
};

export default function authReducer(auth, action){
    switch (action.type){
        case 'login': {
            axios.defaults.headers.common['Authorization'] = `Bearer ${action.auth.jwt}`;
            localStorage.setItem('heroAuth', JSON.stringify(action.auth));
            return {
                ...action.auth,
                isAuthenticated: true
            };
        }
        case 'logout': {
            delete axios.defaults.headers.common['Authorization'];
            localStorage.removeItem('heroAuth');
            return {
                ...initialAuthDefault
            };
        }
        case 'error':
        case 'invalid': {
            delete axios.defaults.headers.common['Authorization'];
            localStorage.removeItem('heroAuth');
            return { ...initialAuthDefault };
        }
        default:    
            throw new Error(`Invalid action from auth: ${action.type}`);
    }
}