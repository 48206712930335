import React from 'react';
import styles from '../login/login.module.scss';
import { usePost } from '../http/httpContext';
import { useAuth } from '../auth/authContext';

export default function Login() {
    const { post } = usePost();
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const { dispatch } = useAuth();

    const handleSubmit = async e => {
        e.preventDefault();
        try{
            const response = await post('auth/local', {identifier: email, password});
            if(!response) {
                return dispatch({ type: 'invalid' });
            }
            dispatch({
                type: 'login',
                auth: {
                    ...response
                }
            })
        } catch {
            dispatch({ type: 'error' });
        }
    }

    return (
        <div className={styles.loginBackground}>

                <div className={styles.loginWrapper}>
                    <div className={styles.title}>Login</div>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Email</label>
                            <input 
                                onChange={e => setEmail(e.target.value)} 
                                type="email" 
                                className="form-control" 
                                id="exampleInputEmail1" 
                                aria-describedby="emailHelp" 
                            />
                            <div id="emailHelp" className="form-text">We'll never share your email with anyone else... except for Ryan.</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                            <input 
                                onChange={e => setPassword(e.target.value)}
                                type="password" 
                                className="form-control" 
                                id="exampleInputPassword1" 
                            />
                        </div>
                        <div className="d-grid">
                            <button type="submit" className="btn btn-primary btn-block">Login</button>
                        </div>
                        <div className={styles.forgottenPassword}>
                            <a href="/reset">Forgot your password?</a>
                        </div>
                    </form>
                </div>
                

        </div>
    )
}