export default function Loading() {
    return (
        <div className="container-fluid h-100">
            <div className="d-flex h-100 justify-content-center align-items-center">
                <div className="loading text-center">
                    Loading...
                </div>
            </div>
        </div>
    )
}